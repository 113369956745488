import * as React from "react"
import { Layout, SliderArtists } from '../components';
import { Helmet } from "react-helmet";


// markup
const About = ({ location }) => {
	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/about.css" rel="stylesheet" type="text/css" />

			</Helmet>

			<section data-name="layout" id={'about_page'}>
				<div className={'page-container'}>

					<div className={'about-questions d-flex justify-content-start align-items-center'}>

						<div className={'col-10 offset-1 container-fluid'}>
							<h2 className={'about-title'}>The Project</h2>
						</div>
					</div>
					<div className={'about-wrapper col-10 container-fluid'}>
						<div className={''}>
							<p>
								Ephemeral Ethernal is a high-end curated NFT platform for affordable drops and web3 projects, focusing on exploring the potential of <strong className={"bold"}>phygital art</strong>.
							</p>
							<p>
								“Phygital” represents the <strong className={"bold"}>intersection between physical and digital art</strong>, leveraged by bringing physicality to digital art, through the use of physical displays for digital artworks or by creating immersive experiences. The inverse process also applies, by opening the door to artists that do not yet experiment with digital art.
							</p>
							<p>
								Ephemeral Ethernal is a part of the Cultural Affairs, a collective driven by a vision to promote <strong className={"bold"}>culture as a vehicle for change</strong>, incorporating other units: <a href="https://www.vhils.com" target="_blank">Vhils Studio</a>, <a href="https://www.under-dogs.net" target="_blank">Underdogs</a>, <a href="https://www.iminente.org" target="_blank">Iminente</a> and many more. The resulting synergies enable a capacity for first-rate curating and a conceptual approach to projects and drops, with Ephemeral Ethernal presenting itself as the centre of this ecosystem for all things pertaining to the digital universe.
							</p>
						</div>
					</div>

					{/* <div className={'artists-container'}>
						<SliderArtists />
					</div> */}

				</div>
			</section>

		</Layout>
	)
}

export default About
